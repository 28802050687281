div.scrollmenu {
  height: 127px;
  background-color: rgb(3, 37, 78);
  overflow: auto;
  white-space: nowrap;
  padding: 3px;
}
div.scrollmenu video {
  display: inline-block;
}
