/* Style the header with a grey background and some padding */
.header {
  overflow: hidden;
  background-color: rgb(1, 28, 39);
  text-decoration: none;
  padding: 9px;
}

.logo {
  text-decoration: none;
  color: rgb(251, 216, 127);
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  display: inline-block;
  margin-top: 7px;
}

.copy-url {
  float: right;
  margin-right: 10px;
  width: 120px;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 700px;
}
.copy-url:hover {
  float: right;
  margin-right: 10px;
  width: 120px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  background-color: rgb(3, 37, 78);
  color: rgb(251, 216, 127);
}

button {
  justify-content: center;
  align-items: center;
  background-color: rgb(251, 216, 127);
  height: 35px;
  border-radius: 5px;
  color: rgb(3, 37, 78);
  font-size: 0.8rem;
  width: 35px;
  margin: 0 0.3rem;
  border: none;
}

.buttonOff {
  background-color: rgb(3, 37, 78);
  color: rgb(251, 216, 127);
}

/* Float the link section to the right */
.header-right {
  float: right;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 200px) {
  .header button {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}
