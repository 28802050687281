.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-color: rgb(1, 28, 39);
  color: white;
  z-index: 5;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}
.right-footer {
  float: right;
  margin-right: 150px;
  margin-top: 6.5px;
  background-color: rgb(251, 216, 127);
  color: rgb(1, 28, 39);
  padding: 2px 7px;
  border-radius: 15px;
}
.left-footer {
  display: inline-block;
  margin-left: 150px;
  margin-top: 6.5px;
  background-color: rgb(251, 216, 127);
  color: rgb(1, 28, 39);
  padding: 2px 7px;
  border-radius: 15px;
  text-decoration: none;
}

.left-footer:hover {
  display: inline-block;
  margin-left: 150px;
  margin-top: 6.5px;
  background-color: rgb(3, 37, 78);
  color: rgb(251, 216, 127);
  padding: 2px 7px;
  border-radius: 15px;
  text-decoration: none;
}

.right-footer:hover {
  display: inline-block;
  margin-left: 150px;
  margin-top: 6.5px;
  background-color: rgb(3, 37, 78);
  color: rgb(251, 216, 127);
  padding: 2px 7px;
  border-radius: 15px;
  text-decoration: none;
}
